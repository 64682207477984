import React from 'react'

import { Section } from './style'

const CoberturaEntrega = () => {
    return (
      <Section className='py-5 d-lg-flex position-relative align-items-center justify-content-lg-between'>
        <div className='container'>
          <div className='row align-items-md-center  justify-content-md-between'>
            <div className='col-12 col-md-6 col-lg-5 pb-4 pb-md-0'>
              <h2 className='fs-32 fs-lg-40 lh-40 lh-lg-50 text-grayscale--500 mb-md-0'>
                Sem cobertura de entrega?
              </h2>
            </div>
            <div className='col-12 col-md-6 col-lg-7 col-xl-5'>
              <p className='fs-16 fs-md-18 lh-19 lh-md-22 mb-0 text-grayscale-500'>
                Se atualizou o endereço e não tem cobertura de entrega, você pode <span className='d-md-block d-lg-inline'>inserir outro local para o recebimento</span> do seu cartão.
              </p>
            </div>
          </div>
        </div>
      </Section>
    )
}

export default CoberturaEntrega
